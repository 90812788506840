<template>
  <!-- <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  > -->
  <!-- <template #overlay>
    <div class="text-center">
      <b-icon-controller
        font-scale="3"
        animation="cylon"
      />
      <p id="cancel-label">
        กรุณารอสักครู่...
      </p>
    </div>
  </template> -->
  <div class="container fn text-white mt-2">
    <div>
      <router-link
        :to="{ name: 'home' }"
        class="text-dark"
      >
        <div class="d-flex align-items-center">
          <div class="box-back text-center">
            <i class="fas fa-long-arrow-left" />
          </div>&nbsp;&nbsp;
          <h5 class="text-center font-weight-bolder m-0">
            เชิญเพื่อนรับเครดิต
          </h5>
        </div>
      </router-link>

      <div class="depo mt-1">
        <div>
          <h3>ข้อมูลสำหรับชวนเพื่อน</h3>
        </div>
        <div class="bg p-1 mb-2">
          <b-row>
            <b-col cols="8">
              <div>
                <span class="text-gray">รหัสชวนเพื่อน</span>
                <p class="highlight">
                  {{ invite_code }}
                </p>

                <b-button
                  variant="success"
                  class="d-none d-md-flex"
                  @click="GetReward()"
                >
                  <i class="fad fa-sack-dollar" /> รับเงินชวนเพื่อน
                  {{
                    Commas(
                      friendData.reduce(
                        (acc, ele) =>
                          acc +
                          (ele.List.length > 0
                            ? ele.List.reduce(
                              (acc2, ListEle) => acc2 + ListEle[ele.ListName],
                              0,
                            )
                            : 0),
                        0,
                      ),
                    )
                  }}
                  บาท
                </b-button>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="d-flex justify-content-end">
                <qrcode-vue
                  :value="`https://liff.line.me/1657255968-xXWKQYw0/register_inv/${invite_code}`"
                  size="100"
                  level="M"
                />
              </div>
            </b-col>
          </b-row>
          <b-button
            variant="success"
            block
            class="d-block d-md-none"
            @click="GetReward()"
          >
            <i class="fad fa-sack-dollar" /> รับเงินชวนเพื่อน
            {{
              Commas(
                friendData.reduce(
                  (acc, ele) =>
                    acc +
                    (ele.List.length > 0
                      ? ele.List.reduce(
                        (acc2, ListEle) => acc2 + ListEle[ele.ListName],
                        0,
                      )
                      : 0),
                  0,
                ),
              )
            }}
            บาท
          </b-button>
        </div>
        <hr>
        <h4>
          ลำดับขั้น ชวนเพื่อน
          <i
            style="color: #ffc800"
            class="fad fa-redo-alt"
            @click="getfriend()"
          />
        </h4>

        <hr>
        <b-row class="neon text-center mt-1 mb-1">
          <b-col
            v-for="(item, index) in friendData"
            :key="item.ListName"
            md="3"
            class="mt-1"
          >
            <div class="card-level-head-text">
              <h5 class="font-weight-bolder">
                <i
                  class="fas fa-crown"
                  style="
                    color: #fbc02d;
                    -webkit-text-stroke: 0.8px rgb(255, 71, 0);
                  "
                />
                {{ item.ListName }}
              </h5>
            </div>
            <div class="card-level-head">
              <div class="pt-1 pb-1">
                <b-row>
                  <b-col
                    cols="6"
                    class="d-flex justify-content-start"
                  >
                    <div class="person">
                      <i class="fad fa-users" />
                      {{ Commas(item.List.length) }} คน
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    class="d-flex justify-content-end"
                  >
                    <div class="balance">
                      {{
                        Commas(
                          item.List.reduce(
                            (acc, ele) => acc + ele[item.ListName],
                            0,
                          ),
                        )
                      }}
                      บาท
                    </div></b-col>
                </b-row>

                <div v-if="item.List.length > 0">
                  <div
                    v-for="ItemList in Paginate(
                      item.List,
                      5,
                      currentPage[index],
                    )"
                    :key="ItemList.id"
                    class="bage"
                  >
                    <b-row>
                      <b-col cols="4">
                        {{ ItemList.fname }}
                      </b-col>
                      <b-col cols="4">
                        <!-- {{ ItemList.fname }} {{ ItemList.sname }} -->
                        {{ ItemList.tel }}
                      </b-col>
                      <b-col cols="4">
                        {{ Commas(ItemList[item.ListName]) }} บาท
                      </b-col>
                    </b-row>
                  </div>
                </div>

                <div
                  v-if="item.List.length > 0"
                  class="demo-spacing-0 mt-1"
                >
                  <b-pagination
                    v-model="currentPage[index]"
                    :total-rows="item.List.length"
                    per-page="5"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="pagination-warning"
                    limit="10"
                    align="center"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <hr>
      </div>
    </div>
  </div>
  <!-- </b-overlay> -->
</template>
<script>
import {
  // BInputGroup,
  // BFormInput,
  BRow,
  BCol,
  BButton,
  BPagination,
  // BOverlay,
  // BIconController,
  // BBadge,
} from 'bootstrap-vue'
// import moment from 'moment-timezone'
import QrcodeVue from 'qrcode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import VueClipboard from 'vue-clipboard2'

export default {
  components: {
    // BInputGroup,
    // BFormInput,
    BRow,
    BCol,
    BButton,

    // BOverlay,
    // BIconController,
    QrcodeVue,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BPagination,
    // BBadge,
  },
  data() {
    return {
      rows: 1,
      currentPage: [1, 1, 1, 1, 1, 1, 1, 1],
      show: true,
      message: '',
      balance: '',
      amount: '',
      friendData: [
        {
          ListName: 'Level1',
          List: [],
        },
        {
          ListName: 'Level2',
          List: [],
        },
        {
          ListName: 'Level3',
          List: [],
        },
        {
          ListName: 'Level4',
          List: [],
        },
        {
          ListName: 'Level5',
          List: [],
        },
        {
          ListName: 'Level6',
          List: [],
        },
        {
          ListName: 'Level7',
          List: [],
        },
        {
          ListName: 'Level8',
          List: [],
        },
      ],
      userData: JSON.parse(localStorage.getItem('userData')),
      User: '',
      invite_code: '',
    }
  },
  mounted() {
    this.invite_code = this.userData.invite_code
    this.getfriend()
  },
  methods: {
    showToast1() {
      this.$toast.success('คัดลอกลิ้งสำเร็จ', {
        position: 'top-right',
        timeout: 3475,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: 'fad fa-paste',
        rtl: false,
      })
      this.message = `https://www.ma5lotto.com/register?ref=${this.invite_code}`
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'คัดลอกลิ้งค์สำเร็จ',
          icon: 'CopyIcon',
          text: '👋 คัดลอกข้อความเรียบร้อยแล้วนะคะ',
          variant,
        },
      })
    },
    // getUser(userData) {
    //   this.show = true
    //   this.$http
    //     .get(`https://wapi.hitler168.com/api/users/show/${userData.userID}`)
    //     .then(response => {
    //       // console.log(response.data)
    //       this.show = false
    //       this.User = response.data
    //       this.getfriend(response.data.invite_code)
    //     })
    //     .catch(error => console.log(error))
    // },
    getfriend() {
      this.show = true
      this.$http
        .get('https://lottothaiapi.linelotto.vip/api/invite/friendlist')
        .then(response => {
          this.show = false
          this.friendData = response.data
        })
        .catch(error => console.log(error))
    },
    GetReward() {
      this.show = true
      this.$http
        .get('https://lottothaiapi.linelotto.vip/api/invite/getreward')
        .then(response => {
          this.show = false
          this.getfriend()
          this.Success(`ยอดจำนวนเงินทั้งหมด ${response.data.mes} บาท`)
        })
        .catch(error => {
          this.SwalError(error.response.data.mes)
        })
    },
    Paginate(array, PageSize, PageNumber) {
      return array.slice((PageNumber - 1) * PageSize, PageNumber * PageSize)
    },
    ShareLine() {
      // new media_line_me.LineButton({
      //   pc: false,
      //   lang: 'en',
      //   type: 'a',
      //   text: '',
      //   withUrl: true,
      // })
      // const url = `https://social-plugins.line.me/lineit/share?url=https://www.lottotheone.com/register?ref=${this.invite_code}`
      // window.open(url, '_blank').focus()
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: 'ทำรายการเรียบร้อยค่ะ',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'warning',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<span>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
/* .container {
  max-width: 540px;
} */
/* asdaas */
.balance-cl {
  background-color: #ffc800;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #000;
  position: relative;
}
p.highlight {
  line-height: 1.9;
  font-size: 1.8rem;
}
.pic {
  max-width: 210px;
}
@media only screen and (max-width: 600px) {
  .pic {
    margin-top: 35px;
    max-width: 160px;
  }
  .pic2 {
    margin-top: 35px;
    max-width: 40px;
  }
}
.m-space {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 35px;
  margin-left: 1px;
  margin-right: 1px;
}
.copy {
  margin-top: 15px;
  border: #490000;
  padding: 10px 25px;
  box-shadow: rgba(99, 99, 99, 0.637) 0px 5px 20px;
  background-color: #d60000;
  border-radius: 38px;
  color: #fff;
}
small {
  font-size: 1rem;
  color: #000;
}
.depo {
  border: 1px solid rgb(190, 190, 190);
  border-radius: 5px;
  padding: 10px;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
  background-color: #fff;
}
.bg {
  background-image: url(/images/head2.svg);
  background-position: center center;
  border-radius: unset;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  z-index: unset;
}
.card-level-head {
  background: rgb(238, 125, 132);
  background: linear-gradient(
    158deg,
    rgba(238, 125, 132, 1) 0%,
    rgba(155, 12, 38, 1) 100%
  );
  border-radius: 12px;
  margin-top: -30px;
}
.card-level-head-text {
  margin: auto;
  background-color: #dedede;
  height: 70px;
  width: 70px;
  border: 5px solid #c6c6c8;
  color: #000;
  border-radius: 50%;
  box-shadow: #464646 0px 5px 5px;
  margin-top: -10px;
  z-index: 1;
  position: relative;
}
.bage {
  margin-top: 5px;
  background-color: #1a57b3c5;
  border-bottom: 1px solid #c6c6c8;
  padding: 10px;
}
.bs {
  margin: auto;
}

.balance {
  background-color: #1a57b3;
  border-radius: 25px 0px 0px 25px;
  padding: 5px;
  box-sizing: border-box;
  width: 90px;
  height: 35px;
  color: rgb(255, 255, 255);
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.342) 0px 5px 5px;
}

.person {
  background-color: #1a57b3;
  border-radius: 0px 25px 25px 0px;
  padding: 5px;
  box-sizing: border-box;
  width: 90px;
  height: 35px;
  color: rgb(255, 255, 255);
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.342) 0px 5px 5px;
}

.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block;
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em;
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top;
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle;
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none;
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9;
}

.resp-sharing-button--pinterest {
  background-color: #bd081c;
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373;
}

.resp-sharing-button--tumblr {
  background-color: #35465c;
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c;
}

.resp-sharing-button--reddit {
  background-color: #5f99cf;
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1;
}

.resp-sharing-button--google {
  background-color: #dd4b39;
}

.resp-sharing-button--google:hover {
  background-color: #c23321;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293;
}

.resp-sharing-button--email {
  background-color: #777;
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e;
}

.resp-sharing-button--xing {
  background-color: #1a7576;
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851;
}

.resp-sharing-button--hackernews {
  background-color: #ff6600;
}
.resp-sharing-button--hackernews:hover,
.resp-sharing-button--hackernews:focus {
  background-color: #fb6200;
}

.resp-sharing-button--vk {
  background-color: #507299;
}

.resp-sharing-button--vk:hover {
  background-color: #43648c;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--telegram {
  background-color: #54a9eb;
}

.resp-sharing-button--telegram:hover {
  background-color: #4b97d1;
}

/* asdasd */
</style>
